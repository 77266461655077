import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { Typography } from '@mui/material';

function Page404() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center gap-y-16 my-auto">
      <Typography variant="h1">
        404
      </Typography>
      <Typography>
        {t('missingPage')}
      </Typography>
    </div>
  );
}

export async function getStaticProps(context) {
  const { locale } = context;
  return {
    props: {
      locale,
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default Page404;
